import {onFind} from "../libs/@elements/init-modules-in-scope";
import * as L from 'leaflet-gpx/gpx';

export function init (element) {
    if(!element.id) {
        return;
    }

    if(!element.dataset.gpxMapPath) {
        return;
    }

    onFind('#' + element.id, function () {
        // Initialize the map
        const map = L.map(element.id, {
            scrollWheelZoom: false
        });

        // Initialize the base layer
        L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; OSM Mapnik <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);

        new L.GPX(element.dataset.gpxMapPath, {
            async: true,
            polyline_options: {
                color: 'blue',
                opacity: 0.75,
                weight: 3,
                lineCap: 'round'
            },
            marker_options: {
                startIconUrl: '/static/icons/map/pin-icon-start.png',
                endIconUrl: '/static/icons/map/pin-icon-end.png',
                shadowUrl: '/static/icons/map/pin-shadow.png'
            }
        }).on('loaded', function(e) {
            const bounds = e.target.getBounds();
            map.fitBounds([bounds.getSouthWest(), bounds.getNorthEast()]);
        }).addTo(map);
    });
}
