"use strict";

const SELECTORS = {
    Container: '.js-seo-footer',
    Select: '.js-seo-footer__select',
    Toggle: '#js-seo-footer__accordion-toggle'
}

let selectElem,
    toggleElem,
    isCurrentlyToggled = false

export function init() {
    Array.from(document.querySelectorAll(SELECTORS.Container)).forEach(containerElem => {
        selectElem = containerElem.querySelector(SELECTORS.Select)
        toggleElem = containerElem.querySelector(SELECTORS.Toggle)

        if (!selectElem) {
            console && console.warn && console.warn('1720703572 SEO Footer JS – mobile <select> elem is missing')
        }

        selectElem && selectElem.addEventListener('change', event => {
            let rootUrl = [window.location.protocol, '//', window.location.host].join('');
            window.location = rootUrl + event.target.value
        })

        if (!toggleElem) {
            console && console.warn && console.warn('1720703572 SEO Footer JS – desktop SEO footer toggle elem is missing')
            return
        }

        toggleElem.addEventListener('change', event => {
            isCurrentlyToggled = event.target.checked
        })

        document.addEventListener('click', event => {
            if (!isCurrentlyToggled) return

            if (!event.target.closest(SELECTORS.Container)) toggleElem.checked = false
        })
    })
}
