/** @jsx h */

import {h, render} from "preact";
import {components}  from 'react-select'
import AsyncSelect  from 'react-select/async'
import {onFind} from "../libs/@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "../libs/@elements/data-set-utils";
import throwError from "../libs/@elements/throw-error";
import {useState} from "preact/hooks";
import {translate} from "../../shared/translations/translations";

const defaultSelectors = {
    base: '.js-typeahead'
};

const defaultOptions = {
    isClearable: true,
    defaultValue: "",
    minLength: 2
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        let options = {
            ...defaultOptions,
            ...options,
            ...transformDataSetOptions(getPrefixedDataSet('typeahead', baseElement))
        };

        render(<TypeaheadConfig isClearable={options.isClearable}
        placeholder={options.placeholder}
        value={options.defaultValue}
        defaultIsOpen={false}
        name={options.name}
        minLength={options.minLength}
        groups={options.groups}
        action={options.action}
        defaultOptions={options.options}
        inputId={options.inputId}/>,
        baseElement);
    });
}

function TypeaheadConfig({
                             defaultOptions = [],
                             value = "",
                             placeholder = "",
                             defaultIsOpen = false,
                             name = "q",
                             minLength = 0,
                             groups = [],
                             action = null,
                             inputId = "typeahead-input"
                         }) {


    const [inputValue, setInputValue] = useState(value);
    const [isOpen, setIsOpen] = useState(defaultIsOpen);

    const loadData = (stringValue, callback) => {
        const _config = window._config || {};
        if (!_config.typeaheadDataUrl) {
            console.warn(`"_config.typeaheadDataUrl" is not set`);
        }

        let data = {
            q: stringValue
        };

        const url = addParamsToUrl(_config.typeaheadDataUrl, data);

        groups = JSON.parse(groups);

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(result => result.json()).then(result => {
            const options = [];
            options.push({label: '', isPlaceholder: true});
            for (let i = 0; i < groups.length; i++) {
                let newGroup = {
                    label: groups[i].title,
                    overviewUrl: groups[i].overviewUrl,
                    isProduct: !!groups[i].isProduct,
                    options: result[groups[i].name]
                };

                options.push(newGroup);
            }

            callback(options);
        }).catch(e => {
            console.error(`Could not load configuration data for typeahead`, e);
        });
    };

    // Track autosuggest submit/ajax reload
    const onSuccessfullInputChange = (searchString) => {
        dataLayer.push({event: 'search', search: {
            search_term: searchString
        }});
    };

    const handleInputChange = (newValue) => {
        let input = newValue;
        setInputValue({ input });
        if(input.length > +minLength) {
            onSuccessfullInputChange(input);
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
        return input;
    };

    const handleChange = (currentSelection) => {
        if (currentSelection.isPlaceholder) {
            handleSubmit();
        } else {
            window.location = currentSelection.url;
        }
    };

    const handleSubmit = (evt) => {
        if (evt) {
            evt.preventDefault();
        }
        window.location.href = action + '?q=' + encodeURIComponent(inputValue.input);
    };

    const styling = {
        control: styles => ({ ...styles, backgroundColor: 'transparent', borderWidth: '0' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#fff' : 'transparent',
            }
        },
        menu: styles => ({ ...styles, backgroundColor: '#fff'})
    };

    return (
        <form action={action} onSubmit={(evt) => handleSubmit(evt)}>
            <AsyncSelect
                inputId={inputId}
                name={name}
                className="react-select-wrapper"
                classNamePrefix="react-select"
                cacheOptions
                menuIsOpen={isOpen}
                loadOptions={isOpen ? loadData : ''}
                defaultOptions
                loadingMessage={() => translate('search-loading')}
                placeholder={placeholder}
                styles={styling}
                onInputChange={handleInputChange}
                onChange={handleChange}
                components={{ Group, GroupHeading, Option }}
            />
        </form>
)
}

const Group = props => {
    let isProduct = !!props.data.isProduct;

    return (
        <components.Group {...props}>
            <div className={isProduct ? 'typeahead__group--product' : ''}>
                {props.children}
            </div>
        </components.Group>
    );
};


const GroupHeading = props => {
    let showLink = !!props.data.overviewUrl;

    return (
        <components.GroupHeading {...props}>
            <div className="row">
                <div className="col">{props.children}</div>
                {showLink ? (
                    <div className="col-7 text-end">
                        <a className="react-select__group-link" href={props.data.overviewUrl + '?q=' + encodeURIComponent(props.selectProps.inputValue)}>
                            {translate('search-showmore')}
                        </a>
                    </div>
                ) : null}
            </div>
        </components.GroupHeading>
    );
};

const Option = props => {
    let hasImage = !!props.data.imgurl;
    let isPlaceholder = props.data.isPlaceholder;

    return (
        <components.Option {...props}>
            {!isPlaceholder ? (
                <div className="stretch-link">
                    <div className="row align-items-center">
                        {hasImage ? (
                            <div className="col-2">
                                <div className="ratio ratio-1x1 bg-sand-20 ratio--max-vh-100">
                                    <div className="ratio-item typeahead__img">
                                        <div className="position-relative h-100">
                                            <img className="typeahead__img__img img-fluid" src={props.data.imgurl} alt={props.data.title} title={props.data.title} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ): null}
                        <div className="col">
                            <div>
                                <a href={props.data.url} className="typeahead__title stretch-link__link">
                                    {props.data.title}
                                </a>
                            </div>
                            {props.data.text ? (
                                <div className="mt-2">{props.data.text}</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null }
        </components.Option>
    );
};


function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.options) {
        try {
            transformedOptions.options = JSON.parse(transformedOptions.options)
        } catch (e) {
            transformedOptions.options = null;
            throwError(OPTIONS_ERROR_MESSAGE);
        }
    }

    return transformedOptions;
}

export const addParamsToUrl = (url, params) =>
    url + (url.indexOf('?') >= 0 ? '&': '?')
    + Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');


const OPTIONS_ERROR_MESSAGE = `Typeahead error: data-typeahead-options has to be a a valid JSON object. Most likely you used single quotes instead of double quotes for the JSON fields.`;
