"use strict";

import Splide from '@splidejs/splide';

export let slider;

export function init (sliderElement, isMobileView = false) {
    const splideOptions = {
        type: 'slide',
        autoWidth: false,
        cover: true,
        arrows: true,
        perPage: 1,
        mediaQuery: 'min',
        padding: { left: '1.25rem', right: '1.25rem' },
        gap: '1.25rem',
        pagination: false
    }

    slider = new Splide( sliderElement, splideOptions ).mount();
}
