"use strict";

import loadScript from "../libs/@elements/load-script";

export function initInScope($scope) {

    if (_config.raffle !== true) {
        return;
    }

    if (_config.friendlyCaptchaV1 !== true) {
        return;
    }

    const apiKey = _config.friendlyCaptchaKey;

    if (!apiKey) {
        return $.Deferred().reject(new Error('friendlyCaptcha key is not set. Please set _config.friendlyCaptchaKey'));
    }

    const frcWidget = $scope.find(".friendlycaptcha");

    if (frcWidget) {
        const locale = _config.locale ? _config.locale.split('_')[0] : 'en'
        loadScript('https://cdn.jsdelivr.net/npm/friendly-challenge@0.9.14/widget.min.js').then(() => {
            const widget = new friendlyChallenge.WidgetInstance(frcWidget, {
                sitekey: apiKey,
                solutionFieldName: 'friendlycaptcha-response',
                language: locale
            })
        }).catch(e => {
            console.log(e);
        });
    }

}

export function submitAjaxForm(url, formData, callback) {
    if (_config.raffle !== true) {
        return;
    }
    if (_config.friendlyCaptchaV1 !== undefined) {

        const apiKey = _config.friendlyCaptchaKey;

        if (!apiKey) {
            return $.Deferred().reject(new Error('friendlyCaptcha key is not set. Please set _config.friendlyCaptchaKey'));
        }

        const frcWidget = find(".friendlycaptcha");
        if (frcWidget) {
            const locale = _config.locale ? _config.locale.split('_')[0] : 'en'
            loadScript('https://cdn.jsdelivr.net/npm/friendly-challenge@0.9.14/widget.min.js').then(() => {
                const widget = new friendlyChallenge.WidgetInstance(frcWidget, {
                    sitekey: apiKey,
                    startMode: 'none',
                    solutionFieldName: 'friendlycaptcha-response',
                    language: locale,
                    doneCallback: function (token) {
                        formData.append('friendlycaptcha-response', token);
                        callback(url, formData);
                    }
                })
                widget.start()
            }).catch(e => {
                console.log(e);
            });
        }
    } else {
        callback(url, formData);
    }

}
