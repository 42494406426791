'use strict';
import $ from 'jquery';
import fetch from '../libs/@elements/fetch';
import initModulesInScope from "../libs/@elements/init-modules-in-scope";
import {init as initTeaserSlider} from "./teaserSlider";

const currentFiltersAttribute = 'data-current-filters';

export function init() {
    let $scope = $('body');
    let $link       = $scope.find('.js-storyHubTeaser__category'),
        $sort       = $scope.find('.js-storyHubTeaser__sorting'),
        $content    = $scope.find('.js-storyHubTeaser__content')

    if($sort.length){
        let pendingRequest;
        $sort.on('change', (e) =>{
            let currentFiltersElem = $scope.find('.js-story-hub__current-filters');

            let url = window.location.pathname + "?ajax=1&full-ajax-form=1&sort=" + e.target.value;

            let currentFiltersString = currentFiltersElem.attr(currentFiltersAttribute);
            if (currentFiltersString) {
                let currentFiltersObject = JSON.parse(currentFiltersString);
                if (currentFiltersObject.hasOwnProperty('category') && currentFiltersObject.category){
                    url += '&category=' + currentFiltersObject.category;
                }
            }

            if(pendingRequest){
                pendingRequest.abort();
            }

            pendingRequest = fetch( url , {
                method: 'GET'
            } )
            $scope.find('.js-loading').removeAttr('hidden');
            $scope.find('.loading-overlay').removeAttr('hidden');

            pendingRequest.then((res) => {
                return res.clone().json()
            }).then((res) => {
                $scope.find('.js-loading').attr('hidden', 'hidden');
                $scope.find('.loading-overlay').attr('hidden', 'hidden');
                if (res.success) {
                    $content.html(res.html);
                    initModulesInScope();
                }
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    console.error(error);
                    $scope.find('.js-loading').attr('hidden', 'hidden');
                    $scope.find('.loading-overlay').attr('hidden', 'hidden');
                }
            })
        })
    }
    if($link.length){
        let pendingRequest;
        $link.on('click', (e) => {
            e.preventDefault();
            let currentFiltersElem = $scope.find('.js-story-hub__current-filters');

            let showall;
            let $target = $(e.target).is('a') ? $(e.target) : $(e.target).closest('a');
            let url = $target.attr('href');

            if(pendingRequest){
                pendingRequest.abort();
            }

            let currentFiltersString = currentFiltersElem.attr(currentFiltersAttribute);
            if (currentFiltersString) {
                let currentFiltersObject = JSON.parse(currentFiltersString);
                if (currentFiltersObject.hasOwnProperty('sort') && currentFiltersObject.sort.length){
                    url += '&sort=' + currentFiltersObject.sort;
                }
            }

            if($target.hasClass('js-storyHubTeaser__category--active')){
                showall = true;
            }

            pendingRequest = fetch( url , {
                method: 'POST'
            } )

            $scope.find('.js-loading').removeAttr('hidden');
            $scope.find('.loading-overlay').removeAttr('hidden');

            pendingRequest.then((res) => {
                return res.clone().json()
            }).then((res) => {
                $scope.find('.js-loading').attr('hidden', 'hidden');
                $scope.find('.loading-overlay').attr('hidden', 'hidden');
                if (res.success) {
                    $link.removeClass('active js-storyHubTeaser__category--active');
                    if(showall){
                        $target.closest('.js-storyHubTeaser__nav').find('.js-storyHubTeaser__category--all').addClass('active js-storyHubTeaser__category--active')
                    }else{
                        $target.addClass('active js-storyHubTeaser__category--active');
                    }
                    $content.html(res.html);
                    initModulesInScope();
                    initTeaserSlider();
                }
            }).catch((error) => {
                if (error.name !== 'AbortError') {
                    console.error(error);
                    $scope.find('.js-loading').attr('hidden', 'hidden');
                    $scope.find('.loading-overlay').attr('hidden', 'hidden');
                }
            })
        });
    }
    if($content.length){
        $content.each((_, storyHubTeaser) => {
            const
                $storyHubTeaser = $(storyHubTeaser)

            let headerHeight;

            $storyHubTeaser.on('fetch.ajax-form', () => {

                if(window.matchMedia('(min-width: 992px)').matches) {
                    headerHeight    = 125;
                }else{
                    headerHeight    = 75;
                }

                $('html, body').animate({
                    scrollTop: $storyHubTeaser.closest('.content-block').offset().top - headerHeight
                }, 500);

            })
        });
    }
}