'use strict';

import {findAllIn, findIn} from '../libs/@elements/dom-utils';
import {onFind} from "../libs/@elements/init-modules-in-scope";
import {getPrefixedDataSet} from '../libs/@elements/data-set-utils';

let defaultOptions = {
    scrollOffset: 0,
    initialPosition: true
};
let defaultSelectors = {
    base: '.js-smoothscroll',
    link: '.js-smoothscroll__link',
    dataOffset: 'smoothscroll-offset'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {

    onFind(selectors.base, function (baseElement) {
        createSmoothScroll(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });

}

export function createSmoothScroll(smoothScroll, options = defaultOptions, selectors = defaultSelectors) {

    let elementOptions = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('smoothscroll', smoothScroll)
    };

    const anchors = findAllIn(defaultSelectors.link, smoothScroll);

    if (elementOptions.initialPosition){
        setTimeout(function() {
            setInitialPosition(smoothScroll, options, selectors);
        }, 1);
    }

    anchors.forEach(element => {
        element.addEventListener('click', function (evt) {
            evt.preventDefault();
            const
                anchor = element,
                target =  findIn(element.hash, smoothScroll) ? findIn(element.hash, smoothScroll) : findIn(element.dataset.target, smoothScroll);

            let scrollNext = false;
            if (element.dataset.scrollNext) {
                scrollNext = true;
            }

            let offset = anchor.dataset.scrollOffset ?  anchor.dataset.scrollOffset : elementOptions.scrollOffset;

            smoothscroll(element, target, offset, selectors, anchor.offsetTop, scrollNext)
        })
    });
}

export function setOffset(offset) {
    defaultOptions.scrollOffset = offset;
}

export function smoothscroll(el, target, offset = defaultOptions.scrollOffset, selectors, anchorOffset, scrollNext) {
    scrollTo(el, target, offset, anchorOffset, scrollNext)
}

function scrollTo(el, target, offset, anchorOffset, scrollNext) {
    let scrollOffset = target.offsetTop - offset;
    if (scrollNext) {
        scrollOffset = target.offsetHeight - offset;
    }

    const fixedOffset = scrollOffset.toFixed(),
        onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll);
                target.focus();
                if(el){
                    history.replaceState({}, '', el.hash);
                }
                if (target === document.activeElement) {
                    return false;
                } else {
                    target.focus();
                }
            }
        };

    window.addEventListener('scroll', onScroll);

    window.scrollTo({
        top: scrollOffset,
        behavior: 'smooth'
    });

}

function setInitialPosition(scope, options, selectors) {
    let hash = window.location.hash;
    if(hash) {
        const
            pattern = new RegExp(hash),
            anchors = findAllIn(selectors.link, scope),
            target = document.querySelector(window.location.hash);

        let offset = options.scrollOffset;

        anchors.forEach((el) => {
            offset = pattern.test(el.getAttribute('href')) && el.getAttribute('data-'+selectors.dataOffset) ? el.getAttribute('data-'+selectors.dataOffset) : offset;
        });

        smoothscroll(this, target, offset, defaultSelectors, 50)
    }
}
