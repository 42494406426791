import {initSliders} from './sliderInit';
import $ from 'jquery';

const
    initiativesSliderOptions = {
        arrows: false,
        dots: false,
        infinite: false,
        slidesToShow: 1,
        adaptiveHeight: false,
        variableWidth: false,
        outerEdgeLimit: true,
    };

export function init() {
    let $sliders = $('body').find('.js-teaser-slider');
    initSliders($sliders, initiativesSliderOptions);
}
