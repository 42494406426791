import {onFind} from "../libs/@elements/init-modules-in-scope";
import {init} from '../libs/@elements/alert-notification';
init({
    key: '__alertNotification',
    renderFunction: function renderFunction({type, title, content, closable = true, styleModifier = '', placement, defaultRender} = {}, options) {
        if (content || title) {
            let containers = options.container;

            containers.map(function(container) {
                container.removeAttribute('hidden');
                container.insertAdjacentHTML('beforeend', (
                    `<div class="alert alert-${type} alert-dismissible fade show ${styleModifier}" role="alert">
                ${title ? (
                        `<h4 class="alert-heading">
                        ${title}
                    </h4>`
                    ) : ''}

                ${closable ? (
                        `<button type="button"
                             class="close"
                             data-bs-dismiss="alert"
                             aria-label="${options.closeText}"
                             title="${options.closeText}">
                            <span aria-hidden="true">&times;</span>
                         </button>`
                    ) : ''}
                ${content ? (
                        `<div>
                        ${content}
                    </div>`
                    ) : ''}
            </div>`));
            });
        }
    }
});

/**********************************
 *
 *      Cookies
 *
 * ********************************/

import * as cookieOverlay from '../../shared/cookie-overlay/cookieOverlay';
cookieOverlay.register();

import * as youtubeVideos from '../../shared/youtube-videos/youtubeVideos';
youtubeVideos.register();

import * as cookieInformation from '../../shared/cookie-overlay/cookieInformation'
cookieInformation.init();
window.initializeCookieElements = cookieInformation.initializeCookieElements

import * as cookieOverlaysInLightGallery from '../../shared/cookie-overlay/cookieOverlaysInLightGallery';
window.initializeCookieOverlaysInLightGallery = cookieOverlaysInLightGallery.initializeCookieOverlaysInLightGallery

/* shared */
import {cachedFetches} from "../../shared/cached-fetches/cached-fetches";
window.cachedFetches = cachedFetches.init();

import {unhideByLoginState} from '../../shared/unhide-by-login-state/unhide-by-login-state'
unhideByLoginState()

import {setActiveRootMenu} from "../../shared/setActiveRootMenu/setActiveRootMenu";
setActiveRootMenu()

import Modal from 'bootstrap/js/dist/modal';
onFind('.modal', function (element) {
    new Modal(element, {})
});

import Alert from 'bootstrap/js/dist/alert';
onFind('.alert', function (element) {
    new Alert(element)
});

import Collapse from 'bootstrap/js/dist/collapse';
onFind('.collapse', function (element) {
    new Collapse(element, {
        toggle: false
    })
});


import * as affixNav from './affix-nav';
affixNav.init();

import * as megaNav from './mega-nav';
megaNav.init();

import * as fadeIn from './fade-in';
fadeIn.init();

import * as ajaxLogin from './ajaxLogin';
ajaxLogin.init();

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as transformIn from './transform-in';
transformIn.init();

import * as toc from '../libs/@elements//toc';
toc.init();

import * as formValidation from './form-validation';
formValidation.init();

/*import * as floatingLabel from '../libs/@elements/floating-labels';
floatingLabel.init();*/

import * as actionChanger from '../libs/@elements/action-changer';
actionChanger.init();

import * as lazyImg from '../libs/@elements/lazy-img';
lazyImg.init({preventNative: true});

import * as typeahead from './typeahead';
typeahead.init();

import * as pageAlert from './page-alert';
pageAlert.init();

import * as wishlist from './wishlist';
wishlist.init();
wishlist.initInScope();

import * as cart from './cart';
cart.init();

import * as loadIcons from './loadIcons';
loadIcons.init();

//fallback
import * as cookieBarFallback from "./cookiePermissions";
_config.cookieServiceDefault = true;

import * as tracking from './tracking';
tracking.init();

import * as tabModule from '../../shared/contents/tab-module.js';
tabModule.init();

import * as fileInput from '../libs/@elements/file-input';
fileInput.init();

import './raffle';

import * as goToLink from '../libs/@elements/go-to-link';
goToLink.init();

import * as teaserCards from './teaser-cards/teaser-cards-slider';
teaserCards.init();

import * as countryMismatchPopup from './countryMismatchPopup'
countryMismatchPopup.init();

import * as wcagLinkTargetBlank from '../../shared/wcag-link-target-blank/wcagLinkTargetBlank'
wcagLinkTargetBlank.init()

import * as heroSlider2 from './hero-slider2';
heroSlider2.init();

import * as seoFooter from './seo-footer';
seoFooter.init();
