"use strict";

import {findAll, on, addClass, findIn, setAttribute, removeAttribute} from "../libs/@elements/dom-utils";

export function init() {
    let videos = findAll('.js-video');//only asset videos

    videos.forEach((videoContainer) => {
        const video = findIn('.js-video__video', videoContainer),
              playButton = findIn('.js-video__play', videoContainer),
              videoLoading = findIn('.js-video__loading', videoContainer),
              posterElement = findIn('.js-video__poster', videoContainer);

        if (!video) {
            console && console.error && console.error('1685974308 – video.js: no video element found');
            return;
        }

        const loadVideo = () => {
            if(videoLoading) {
                removeAttribute('hidden', videoLoading);
            }
            video.play();

            video.onplaying = function () {
                setAttribute('hidden', true, playButton);
                addClass('is-playing', videoContainer);
                if(videoLoading) {
                    setAttribute('hidden', true, videoLoading);
                }
            }
        };

        playButton && on('click', () => loadVideo(), playButton);
        posterElement && on('click', () => loadVideo(), posterElement);
    });
}
