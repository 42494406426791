"use strict";

import throwError from "../libs/@elements/throw-error";
import {onFind} from "../libs/@elements/init-modules-in-scope";
import Splide from '@splidejs/splide';

const Selectors = {
    Slider: '.js-hero-slider2.splide',
    NextArrow: '.js-hero-slider2__arrow-next',
    PrevArrow: '.js-hero-slider2__arrow-prev',
}

const States = {
    HasCustomNavElements: 'js-hero-slider2--has-custom-nav-elements'
}

const splideOptions = {
    type: 'loop',
    perPage: 1,
    perMove: 1,
    arrows: false,
    pagination: false,
    mediaQuery: 'min',
    drag: true,
    breakpoints: {
        768: {
            drag: false,
        }
    }
}

const mediaDesktop = "(min-width: 768px)";

export function init() {
    onFind(Selectors.Slider, element => {
        const hasCustomNavElements = Array.from(element.classList).indexOf(States.HasCustomNavElements) > -1,
              slider = new Splide(element, splideOptions)

        slider.on('ready', event => {
            if (!hasCustomNavElements) return

            const sliderNextElems = element.querySelectorAll(Selectors.NextArrow),
                  sliderPrevElems = element.querySelectorAll(Selectors.PrevArrow)

            Array.from(sliderNextElems).forEach(sliderNextElem => {
                sliderNextElem.addEventListener('click', () => {window.matchMedia(mediaDesktop).matches && slider.go('>')})
            });

            Array.from(sliderPrevElems).forEach(sliderPrevElem => {
                sliderPrevElem.addEventListener('click', () => {window.matchMedia(mediaDesktop).matches && slider.go('<')})
            });
        })

        slider.mount()
    })
}
