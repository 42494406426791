"use strict";

import $ from 'jquery'
import 'slick-carousel';
import {loadImg} from "../lazy-img";
import {onEnterViewPort} from "../viewport-utils";
import {getPrefixedDataSet} from "../data-set-utils"

export const initInScope = createInitInScope('.js-slider');

export function createInitInScope(selector, options) {
    return function ($scope) {
        let $sliderElements = $scope.find(selector);
        $sliderElements.each(function () {
            let $slider = $(this);

            let sliderOptions = {
                infinite: false,
                dots: true,
                slidesToShow: 1,
                swipeToSlide: true,
                ...options,
                ...getPrefixedDataSet('slider', $slider)
            };

            const $tabPaneParent = $slider.closest('.tab-pane:not(.active)');
            const $collapseParent = $slider.closest('.collapse');
            const $modalParent = $slider.closest('.modal');

            if ($tabPaneParent && $tabPaneParent.length) {
                /*wait for the initializiation of the slider if it is hidden in a tab */
                const $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-bs-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');

                $tabTrigger.one('shown.bs.tab', function () {
                    initSlider($slider, sliderOptions);
                });

            } else if ($collapseParent && $collapseParent.length) {
                /* wait until slide is shown if it is initially hidden in a collapse/accordion */
                $collapseParent.one('shown.bs.collapse', function () {
                    initSlider($slider, sliderOptions);
                });

            } else if ($modalParent && $modalParent.length) {
                /* wait until slide is shown if it is initially  hidden in a modal */
                $modalParent.one('shown.bs.modal', function () {
                    initSlider($slider, sliderOptions);
                });

            } else {
                // no tab, accordion, modal -> init slider as usual
                initSlider($slider, sliderOptions);
            }
        });

        return $sliderElements;
    }
}

function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}
