"use strict";

import throwError from "../../libs/@elements/throw-error"
import {onFind} from "../../libs/@elements/init-modules-in-scope";
import {findIn, findAllIn} from "../../libs/@elements/dom-utils";
import * as slider from './slider';

const Selectors = {
    Container: '.teaser-cards-slider',
    Slider: '.teaser-cards-slider__content',
    SlideItem: '.teaser-cards-slider__item',
    SplideNodeType: '[data-splide-node]'
}

const Classnames = {
    Splide: {
        splide: ['splide', 'teaser-cards-slider__content'],
        track: ['splide__track'],
        list: ['splide__list'],
        slide: ['splide__slide']
    }
}

const Attributes = {
    SplideNodes: 'data-splide-node'
}

let containerEl;
let sliderEl;
const mediaDesktop = "(min-width: 768px)";

export function init () {
    onFind(Selectors.Container, (element) => {
        const isMobileView =  !window.matchMedia(mediaDesktop).matches;
        if(!isMobileView) {
            return;
        }

        containerEl = element ? element : throwError("Teaser Slider JS – Container missing");
        addSplideClasses();
        sliderEl = findIn(Selectors.Slider, containerEl);
        if(!sliderEl) {
            return;
        }
        sliderEl && slider.init(sliderEl, isMobileView);
    })
}

function addSplideClasses() {
    let splideNodes = findAllIn(Selectors.SplideNodeType, containerEl);
    if(splideNodes.length > 0) {
        splideNodes.forEach((element) => {
            let splideNodeType = element.getAttribute(Attributes.SplideNodes);
            if(splideNodeType) {
                element.className = "";
                element.classList.add(...Classnames.Splide[splideNodeType]);
            }
        })
    }
}
