"use strict";

import {onEnterViewPort} from "../libs/@elements/viewport-utils";
import {initInScope,onFind, cleanUpInScope} from "../libs/@elements/init-modules-in-scope";
import {findIn,removeClass} from '../libs/@elements/dom-utils';
import {initializeCookieElements} from '../../shared/cookie-overlay/cookieInformation';

export function init () {
    onFind('.js-render-template', function (renderTemplates) {
        onEnterViewPort(renderTemplates, function (renderItem) {
            renderTemplate(renderItem)
        }, { offset: 300 });
    });
}

export function renderTemplate(renderItem) {
    let template = findIn('.js-render-template__template',renderItem);
    let parent = renderItem.parentNode;

    cleanUpInScope(renderItem);

    if(template) {
        renderItem.innerHTML = template.innerHTML;
        removeClass('js-render-template',renderItem);

        template.remove();
    }

    initInScope(renderItem);

    //template has to be initialized...
    initializeCookieElements();
}

export function renderAllTemplates() {
    onFind('.js-render-template', function (renderTemplate) {
        renderTemplate(renderTemplate);
    });
}